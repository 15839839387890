// Nucleo Font
//@import "fonts/nucleo/Nucleo"; // It doesn't want to work

.error-header {
  font-size: 12em;
  color: #ff0000;
  line-height: 2em;
  text-shadow: 4px 8px 1px #7b7777;
}

// Custom Spinner

.spinner-grow {
  width: 15rem;
  min-height: 15rem;
  border-radius: 50%;
  animation: spinner-grow .75s linear infinite;
  background: currentColor;
  display: inline-block;
}

@keyframes spinner-grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.ui-block {
  background: #f9f7f7b5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
}

.event-preview {
  //width: 500px;
  object-fit: scale-down;
  height: auto;
  border: 5px solid #fff;
  box-shadow: -1px 2px 4px 0px #000;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #d8121b 0, #75757575 100%) !important;
}

.sb-avatar__text {
  color: #212529 !important;
  background: #adb5bd !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #75757575;
  border-radius: 5px;
}

.navbar-horizontal .navbar-brand img {
  height: initial;
}

.btn-no-style {
  -webkit-appearance: media-slider !important;
  background: none;
}

.sidenav.active {
  transform: translateX(0) !important;
}

.spinner-small {
  width: 2rem;
  height: 2rem;
  min-height: initial;
}

.nav-item.nav-with-child > .nav-item-child {
  list-style: none;
  height: 0;
  min-height: 0px;
  overflow: hidden !important;
  padding: 0px 1.5rem;
  transition: all 0.5s ease-in-out;
  margin-left: 25px;
}

.nav-item.nav-with-child.nav-item-expanded > .nav-item-child {
  padding: 0.5rem 1.5rem;
  position: relative;
  height: auto;
  min-height: 50px;
  display: block;
  transition: all 0.5s ease-in-out;
}


tr {
  td {
    white-space: break-spaces !important;
  }
}

.accordion .card-header:after {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: '\f0d7';
  font-weight: 900;
}